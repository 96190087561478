import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Styled from 'styled-components'
import Wrapper from '../components/wrapper'
import SEO from '../components/seo'
import Body from '../components/body'
import Header from '../components/header'
import ContactSnippet from '../components/contactSnippet'
import Footer from '../components/footer'

const Spacer = Styled.div`
    @media screen and (min-width: 720px) {
        margin-bottom: 50px;    
    }
`
const Image = Styled(Img)`
    float: left;
    width: 125px;
    margin: 5px;
    border-radius: 10%;
    filter: grayscale(100%);
`
const Paragraph = Styled.p`
    margin: 5px 5px 40px 5px;
`

const AboutKimPage = ({ data }) => (
    <Wrapper>
      <SEO title="About" />
      <Header />
      <Body>
        <Spacer />
        <Image fluid={data.kim.childImageSharp.fluid} alt='Dr. Kimberly Ludwigsen.' />
        <Paragraph>After graduating with academic honors and distinction from Goucher College, Dr. Ludwigsen earned a Master’s Degree in Social Work (MSW) from the University of Pennsylvania. She earned certificates in couples and family therapy and sex therapy at the Council for Relationships in Philadelphia, a nationally recognized post-graduate program.  She is an  American Association of Sexuality Educators, Counselors and Therapists Certified Sex Therapist (AASECT-CST) trained to provide in-depth psychotherapy specializing in treating clients with sexual issues and concerns. Kim received her Doctoral Degree in Human Sexuality at Widener University. The Human Sexuality program moved from the University of Pennsylvania in 1999 and continues to be the most sought after program in the nation. Dr. Ludwigsen is a member of AASECT, the National Association of Social Workers (NASW).</Paragraph>
        <Paragraph>Kim works with individuals and couples. Her specialties are Sex Therapy and LGBTQA Issues.  Her therapeutic style draws from a variety of treatment approaches, including but not limited to Couple and Family Systems Therapy, Psycho-dynamic Therapy, Sex Positive Sex Therapy, Pleasure-Oriented Model for Sexual Satisfaction, Conflict Resolution, Narrative Therapy, Cognitive Behavioral Therapy and Mindfulness Practice. Her therapeutic approach honors and respects you as a whole person – your emotional, mental, and sexual self.</Paragraph>
        <Paragraph>Kim will help you to explore and understand all aspects of yourself with a goal toward self-satisfaction and self-appreciation in a safe, respectful and above all non-judgmental environment.</Paragraph>
        <ContactSnippet />
      </Body>
      <Footer />
    </Wrapper>
)

export const query = graphql`
    query {
        kim: file(relativePath: { eq: "kim.jpg" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth: 125) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
    }
`

export default AboutKimPage
